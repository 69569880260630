import React from 'react';
import Helmet from 'react-helmet';

const Title = (props) => {
    return (
        <Helmet>
            <title>
                {props.children ? props.children + ' - ' : ''}Fatturati
            </title>
        </Helmet>
    );
}

export default Title;
